import { z } from 'zod';

export const listInvitesQuerySchema = z.object({
  status: z.enum(['pending', 'consumed', 'active']).optional(),
  invitesQuestId: z.string().uuid().optional(),
  page: z.coerce.number().int().min(1),
  page_size: z.coerce.number().int().max(50).positive().default(10),
});

export const listInvitesPathSchema = z.object({
  subdomain: z.string(),
  userId: z.string().uuid(),
});

export const listInvitesInputSchema = listInvitesQuerySchema.merge(listInvitesPathSchema);

export const listInvitesOutputSchema = z.object({
  data: z.array(
    z.object({
      userId: z.string().uuid(),
      status: z.enum(['pending', 'consumed', 'active']),
      joinedAt: z.string().datetime(),
      name: z.string().nullable(),
      avatar: z.string().nullable(),
      xp: z.number(),
    }),
  ),
});

export const listInvitesPublicApiOutputSchema = z.array(
  z.object({
    userId: z.string().uuid(),
    status: z.enum(['pending', 'consumed', 'active']),
    joinedAt: z.string().datetime(),
    name: z.string().nullable(),
    xp: z.number(),
  }),
);

export type ListInvitesInput = z.infer<typeof listInvitesInputSchema>;
export type ListInvitesOutput = z.infer<typeof listInvitesOutputSchema>;
export type ListInvitesPublicApiOutput = z.infer<typeof listInvitesPublicApiOutputSchema>;
