import { impersonateUserSchema } from '@zealy/schemas';

import { mutation, noBody } from '../../utils';

export const impersonateUser = mutation({
  method: 'POST',
  path: '/impersonate',
  body: impersonateUserSchema,
  responses: {
    200: noBody(),
  },
});
