import { z } from 'zod';

export const retryCommunityWebhookEventPathSchema = z.object({
  subdomain: z.string(),
  webhookId: z.string().uuid(),
  eventId: z.string().uuid(),
});

export const retryCommunityWebhookEventInputSchema = retryCommunityWebhookEventPathSchema;

export type RetryCommunityWebhookEventInput = z.infer<typeof retryCommunityWebhookEventInputSchema>;
