import { router } from '../../utils';
import { createUser } from './createUser';
import { createUserV2 } from './createUserV2';
import { getUser } from './getUser';
import { getUserByField } from './getUserByField';
import { updateUser } from './updateUser';
import { updateUserEmail } from './updateUserEmail';

export const userContract = router(
  {
    create: createUser,
    createV2: createUserV2,
    get: getUser,
    update: updateUser,
    updateUserEmail: updateUserEmail,
    getUserByField: getUserByField,
  },
  {
    pathPrefix: '/users',
  },
);
