import { z } from 'zod';

export const discordOAuth2CallbackQuerySchema = z.object({
  code: z.string(),
  subdomain: z.string().optional(),
  state: z.string(),
});

export const discordOAuth2CallbackInputSchema = discordOAuth2CallbackQuerySchema;

export type DiscordOAuth2CallbackInput = z.infer<typeof discordOAuth2CallbackInputSchema>;
