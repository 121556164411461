import { getZealyConnectStatusOutputSchema, getZealyConnectStatusPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getZealyConnectStatus = query({
  method: 'GET',
  path: '/:questId/zealy_connect/status',
  pathParams: getZealyConnectStatusPathSchema,
  responses: {
    200: getZealyConnectStatusOutputSchema,
  },
});
