import { router } from '../../utils';
import { createPromotionBooking } from './createPromotionBooking';
import { listPromotionBooking } from './listPromotionBooking';

export const promotionBookingContract = router(
  {
    createPromotionBooking,
    listPromotionBooking,
  },
  {
    pathPrefix: '/communities/:subdomain/promotion-booking',
  },
);
