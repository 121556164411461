import { createSupportTicketBodySchema, createSupportTicketPathSchema } from '@zealy/schemas';

import { mutation, noBody } from '../../utils';

export const createSupportTicket = mutation({
  method: 'POST',
  path: '/ticket',
  pathParams: createSupportTicketPathSchema,
  body: createSupportTicketBodySchema,
  responses: {
    200: noBody(),
  },
});
