import { router } from '../../utils';
import { createCommunityWebhook } from './createCommunityWebhook';
import { deleteCommunityWebhook } from './deleteCommunityWebhook';
import { listAvailableEventTypes } from './listAvailableEventTypes';
import { listCommunityWebhookEvents } from './listCommunityWebhookEvents';
import { listCommunityWebhooks } from './listCommunityWebhooks';
import { updateCommunityWebhook } from './updateCommunityWebhook';

export const publicCommunityWebhookContract = router(
  {
    create: createCommunityWebhook,
    delete: deleteCommunityWebhook,
    list: listCommunityWebhooks,
    listAvailableEventTypes: listAvailableEventTypes,
    listEvents: listCommunityWebhookEvents,
    update: updateCommunityWebhook,
  },
  {
    pathPrefix: '/communities',
  },
);
