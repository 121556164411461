import { router } from '../../utils';
import { getNFTCollectionMetadata } from './getNFTCollectionMetadata';
import { getTokenContractMetadata } from './getTokenContractMetadata';

export const blockchainContract = router(
  {
    getNFTCollectionMetadata,
    getTokenContractMetadata,
  },
  {
    pathPrefix: '/blockchain',
  },
);
