import { router } from '../../utils';
import { createQuest } from './createQuest';
import { listQuests } from './listQuests';

export const publicQuestContract = router(
  {
    listQuests: listQuests,
    createQuest: createQuest,
  },
  {
    pathPrefix: '/communities/:subdomain/quests',
  },
);
