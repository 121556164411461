import {
  listReviewsOutputSchema,
  listReviewsPathSchema,
  listReviewsPublicApiQuerySchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const listReviews = query({
  summary: 'List reviews for a community',
  method: 'GET',
  path: '',
  pathParams: listReviewsPathSchema,
  query: listReviewsPublicApiQuerySchema,
  responses: {
    200: listReviewsOutputSchema,
  },
});
