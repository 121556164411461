import { z } from 'zod';

import { moduleSchema } from './common';

export const listTemplateQuestsPathSchema = z.object({
  subdomain: z.string(),
});

export const listTemplateQuestsOutputSchema = z.object({
  data: z.array(moduleSchema),
});

export type ListTemplateQuestsOutput = z.infer<typeof listTemplateQuestsOutputSchema>;
