import { listCommunityWebhooksOutputSchema, listCommunityWebhooksPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const listCommunityWebhooks = query({
  summary: 'List all webhooks of your community',
  method: 'GET',
  path: '/:subdomain/webhooks',
  pathParams: listCommunityWebhooksPathSchema,
  responses: {
    200: listCommunityWebhooksOutputSchema,
  },
});
