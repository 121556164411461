import { router } from '../../utils';
import { getLeaderboard } from './getLeaderboard';
import { getUserRank } from './getUserRank';
import { listCommunitySprints } from './listCommunitySprints';

export const leaderboardContract = router(
  {
    get: getLeaderboard,
    getRank: getUserRank,
    listCommunitySprints: listCommunitySprints,
  },
  {
    pathPrefix: '/communities/:subdomain/leaderboard',
  },
);
