import { router } from '../../utils';
import { getLeaderboard } from './getLeaderboard';
import { listSprints } from './listSprints';

export const publicLeaderboardContract = router(
  {
    get: getLeaderboard,
    listSprints: listSprints,
  },
  {
    pathPrefix: '/communities/:subdomain/leaderboard',
  },
);
