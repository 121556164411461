import { z } from 'zod';

export const getBillingPortalUrlPathSchema = z.object({
  subdomain: z.string(),
});

export const getBillingPortalUrlInputSchema = getBillingPortalUrlPathSchema;

export type GetBillingPortalUrlInput = z.infer<typeof getBillingPortalUrlInputSchema>;

export const getBillingPortalUrlOutputSchema = z.object({
  url: z.string(),
});

export type GetBillingPortalUrlOutput = z.infer<typeof getBillingPortalUrlOutputSchema>;
