import { z } from 'zod';

export const getTotalMembersOutputSchema = z.object({
  isComputed: z.boolean(),
  data: z
    .object({
      value: z.number(),
    })
    .nullable(),
});
