import { z } from 'zod';

export const updateUserEmailBodySchema = z.object({
  email: z.string().email(),
  otp: z.string().length(6),
});

export const updateUserEmailPathSchema = z.object({
  id: z.string().uuid(),
});

export const updateUserEmailInputSchema =
  updateUserEmailPathSchema.merge(updateUserEmailBodySchema);

export type UpdateUserEmailInput = z.infer<typeof updateUserEmailInputSchema>;
