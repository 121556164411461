import {
  getNFTCollectionMetadataOutputSchema,
  getNFTCollectionMetadataPathSchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const getNFTCollectionMetadata = query({
  method: 'GET',
  path: '/:network/nft/:contract/metadata',
  pathParams: getNFTCollectionMetadataPathSchema,
  responses: {
    200: getNFTCollectionMetadataOutputSchema,
  },
});
