import { z } from 'zod';

export const createSupportTicketPathSchema = z.object({
  subdomain: z.string(),
});

export const createSupportTicketBodySchema = z.object({
  description: z.string(),
  email: z.string().email(),
  files: z.array(z.string()),
  communityId: z.string().optional(),
  type: z.enum(['problem', 'feedback', 'question']),
});

export const createSupportTicketInputSchema = createSupportTicketBodySchema.merge(
  createSupportTicketPathSchema,
);

export const createSupportTicketOutputSchema = z.void();

export type CreateSupportTicketInput = z.infer<typeof createSupportTicketBodySchema>;

export type CreateSupportTicketOutput = z.infer<typeof createSupportTicketOutputSchema>;
