import { z } from 'zod';

import { networkSchema } from '@zealy/utils';

export const getMemberPathSchema = z.object({
  subdomain: z.string(),
  userId: z.string().uuid(),
});

export const getMemberInputSchema = getMemberPathSchema;

const invitesSchema = z.array(
  z.object({
    userId: z.string(),
    status: z.enum(['active', 'pending', 'consumed']),
    joinedAt: z.string().datetime().nullable(),
    xp: z.number(),
  }),
);

export const getMemberPublicApiOutputSchema = z.object({
  id: z.string(),
  avatar: z.string().nullable(),
  connectedWallet: z.string().nullable(),
  name: z.string().nullable(),
  email: z.string().nullable(),
  discordId: z.string().nullable(),
  twitterId: z.string().nullable(),
  discordHandle: z.string().nullable(),
  tiktokUsername: z.string().nullable(),
  twitterUsername: z.string().nullable(),
  verifiedBlockchainAddresses: z.record(z.string()).optional().nullable(),
  unVerifiedBlockchainAddresses: z.record(z.string()).optional().nullable(),
  xp: z.number(),
  rank: z.number(),
  level: z.number(),
  invites: invitesSchema,
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  referrerUrl: z.string().nullable(),
  referrerId: z.string().nullable(),
  banReason: z.string().nullable(),
  role: z.enum(['admin', 'reviewer', 'guest', 'editor', 'banned']).nullable(),
  isBanned: z.boolean(),
});

export const getMemberOutputSchema = z.object({
  id: z.string(),
  displayedInformation: z.array(z.string()),
  name: z.string().nullable(),
  avatar: z.string().nullable(),
  goal: z.string().nullable(),
  isMe: z.boolean(),
  xp: z.number(),
  interests: z.array(z.string()).nullable(),
  rank: z.number(),
  role: z.enum(['admin', 'reviewer', 'guest', 'editor', 'banned']).nullable(),
  level: z.number(),
  karma: z.number(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  restoredAt: z.string().datetime().nullable(),
  ethWallet: z.string().optional(),
  discordHandle: z.string().optional().nullable(),
  banReason: z.string().optional().nullable(),
  twitterUsername: z.string().optional().nullable(),
  addresses: z.record(z.string()).optional().nullable(),
  verifiedAddresses: z.record(networkSchema, z.string()).optional(),
  tiktokUsername: z.string().optional().nullable(),
  tiktokProfileLink: z.string().optional().nullable(),
  tiktokDisplayName: z.string().optional().nullable(),
  tiktokFollowersCount: z.number().optional().nullable(),
  tweetCount: z.number().optional().nullable(),
  twitterFollowersCount: z.number().optional().nullable(),
  deletedAt: z.string().datetime().nullable(),
  invites: invitesSchema,
  cheatStatus: z.enum(['ok', 'warning', 'restricted']).optional(),
  restrictedUntil: z.string().datetime().optional().nullable(),
  cheatScore: z.number().optional(),
  lastRestrictedAt: z.string().datetime().optional().nullable(),
  socialAccounts: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        type: z.string(),
        verified: z.boolean(),
      }),
    )
    .optional()
    .nullable(),
  guilds: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        icon: z.string().nullable(),
        owner: z.boolean(),
      }),
    )
    .optional()
    .nullable(),
  accounts: z
    .array(
      z.object({
        id: z.string(),
        userId: z.string(),
        tokenStatus: z.string(),
        verified: z.boolean().nullable(),
        accountType: z.string().nullable(),
        authentification: z.string(),
        createdAt: z.string().datetime().nullable(),
        updatedAt: z.string().datetime().nullable(),
      }),
    )
    .optional()
    .nullable(),
  isBanned: z.boolean(),
});

export type GetMemberInput = z.infer<typeof getMemberInputSchema>;

export type GetMemberPublicApiOutput = z.infer<typeof getMemberPublicApiOutputSchema>;

export type GetMemberOutput = z.infer<typeof getMemberOutputSchema>;
