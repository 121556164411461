import { z } from 'zod';

export const createUserBodySchema = z.object({
  email: z.string().email(),
  otp: z.string().length(6),
});

export const createUserInputSchema = createUserBodySchema;

export const createUserOutputSchema = z.object({
  id: z.string(),
});

export type CreateUserInput = z.infer<typeof createUserBodySchema>;

export type CreateUserOutput = z.infer<typeof createUserOutputSchema>;
