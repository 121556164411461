import {
  createQuestInputSchema,
  createQuestOutputSchema,
  createQuestQuerySchema,
} from '@zealy/schemas';

import { mutation } from '../../utils';

export const createQuest = mutation({
  method: 'POST',
  path: '',
  body: createQuestInputSchema,
  query: createQuestQuerySchema,
  responses: {
    200: createQuestOutputSchema,
  },
});
