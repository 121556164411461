import {
  createCommunityWebhookBodySchema,
  createCommunityWebhookOutputSchema,
  createCommunityWebhookPathSchema,
} from '@zealy/schemas';

import { mutation } from '../../utils';

export const createCommunityWebhook = mutation({
  summary: 'Create a new webhook',
  method: 'POST',
  path: '/:subdomain/webhooks',
  pathParams: createCommunityWebhookPathSchema,
  body: createCommunityWebhookBodySchema,
  responses: {
    200: createCommunityWebhookOutputSchema,
  },
});
