import { router } from '../../utils';
import { createQuest } from './createQuest';
import { generateRedirectUrlForApiQuestTask } from './generateRedirectUrlForApiQuestTask';
import { getZealyConnectStatus } from './getZealyConnectStatus';
import { listTaskValidations } from './listTaskValidations';
import { testApiQuestEndpoint } from './testApiQuestEndpoint';
import { testClaimQuest } from './testClaimQuest';
import { verifyZealyConnectCallback } from './verifyZealyConnectCallback';

export const questContract = router(
  {
    testApiQuestEndpoint: testApiQuestEndpoint,
    createQuest: createQuest,
    testClaimQuest: testClaimQuest,
    listTaskValidations: listTaskValidations,
    generateRedirectUrlForApiQuestTask: generateRedirectUrlForApiQuestTask,
    verifyZealyConnectCallback: verifyZealyConnectCallback,
    getZealyConnectStatus: getZealyConnectStatus,
  },
  {
    pathPrefix: '/communities/:subdomain/quests',
  },
);
