import { z } from 'zod';

export const createUserAccountBodySchema = z.object({
  // add body
});

export const createUserAccountPathSchema = z.object({
  // add path params : have to be the same name as path in TRCP
});

export const createUserAccountInputSchema = createUserAccountPathSchema.merge(
  createUserAccountBodySchema,
);

export const createUserAccountOutputSchema = z.object({
  // add response
});

export type CreateUserAccountInput = z.infer<typeof createUserAccountBodySchema>;

export type CreateUserAccountOutput = z.infer<typeof createUserAccountOutputSchema>;
