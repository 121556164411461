import {
  getAnalyticsPathSchema,
  getClaimedQuestsQuerySchema,
  getTimeSeriesOutputSchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const getClaimedQuests = query({
  method: 'GET',
  path: '/claimed-quests',
  pathParams: getAnalyticsPathSchema,
  query: getClaimedQuestsQuerySchema,
  responses: {
    200: getTimeSeriesOutputSchema,
  },
});
