import { z } from 'zod';

export const listCommunitySecretOutputSchema = z.object({
  secrets: z.array(
    z.object({
      prefix: z.string(),
      createdAt: z.string(),
      deletedAt: z.string().nullable(),
    }),
  ),
});

export type ListCommunitySecretOutput = z.infer<typeof listCommunitySecretOutputSchema>;
