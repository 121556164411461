import { z } from 'zod';

import { tipTapDocumentSchema } from '../common';
import { baseSprintSchema } from './common';

export const createLeaderboardSprintBodySchema = z.object({
  endingAt: z.string(),
  startingAt: z.string(),
  rewards: z.union([tipTapDocumentSchema, z.object({})]),
  includedQuestIds: z.array(z.string().uuid()).min(1),
  rewardZone: z.number().nullish(),
  name: z.string().optional().nullable(),
  description: z.string().max(3000).optional().nullable(),
  image: z.string().optional().nullable(),
  color: z.string().optional().nullable(),
});

export const createLeaderboardSprintPathSchema = z.object({
  subdomain: z.string(),
});

export const createLeaderboardSprintInputSchema = createLeaderboardSprintBodySchema.merge(
  createLeaderboardSprintPathSchema,
);

export const createLeaderboardSprintOutputSchema = baseSprintSchema;

export type CreateLeaderboardSprintInput = z.infer<typeof createLeaderboardSprintInputSchema>;

export type CreateLeaderboardSprintOutput = z.infer<typeof createLeaderboardSprintOutputSchema>;
