import { router } from '../../utils';
import { createSupportTicket } from './createSupportTicket';

export const supportContract = router(
  {
    create: createSupportTicket,
  },
  {
    pathPrefix: '/communities/:subdomain/support',
  },
);
