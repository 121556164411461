import { z } from 'zod';

export const getSubscriptionPathSchema = z.object({
  subdomain: z.string(),
});

export const getSubscriptionInputSchema = getSubscriptionPathSchema;

export type GetSubscriptionInput = z.infer<typeof getSubscriptionInputSchema>;

export const getSubscriptionOutputSchema = z.object({
  nextBillingDate: z.string().datetime().nullable(),
  usageStartAt: z.string().datetime(),
  usageResetAt: z.string().datetime(),
  basePrice: z.number(),
  includedUnits: z.number().nullable(),
  planName: z.string(),
  currentUsage: z.number(),
  canExceedCap: z.boolean(),
  isCancelled: z.boolean(),
  costPerExtraUnit: z.number(),
  discountAmount: z.number(),
  extraUnitsConsumed: z.number(),
  discountPercentage: z.number(),
  extraUnitsCost: z.number(),
  billingPeriod: z.enum(['day', 'week', 'month', 'year']).nullable(),
  totalCostWithDiscount: z.number(),
  totalCost: z.number(),
  trialPeriodEndAt: z.string().datetime().nullable(),
  isFreeTrial: z.boolean(),
  canAccessFreeTrial: z.boolean(),
  downgradePlanId: z.string().nullable(),
  downgradePlanInterval: z.enum(['month', 'year']).nullable(),
});

export type GetSubscriptionOutput = z.infer<typeof getSubscriptionOutputSchema>;
