import {
  getAnalyticsPathSchema,
  getRangeAnalyticsQuerySchema,
  getTopQuestsOutputSchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const getTopQuests = query({
  method: 'GET',
  path: '/top-quests',
  pathParams: getAnalyticsPathSchema,
  query: getRangeAnalyticsQuerySchema,
  responses: {
    200: getTopQuestsOutputSchema,
  },
});
