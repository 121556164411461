import { getPricingTableOutputSchema, getPricingTablePathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getPricingTable = query({
  method: 'GET',
  path: '/pricing-table',
  pathParams: getPricingTablePathSchema,
  responses: {
    200: getPricingTableOutputSchema,
  },
});
