import { z } from 'zod';

import { questSchema } from './quest';

export const getTemplateQuestPathSchema = z.object({
  subdomain: z.string(),
  questId: z.string().uuid(),
});

export const getTemplateQuestOutputSchema = questSchema;

export type GetTemplateQuestInput = z.infer<typeof getTemplateQuestPathSchema>;
export type GetTemplateQuestOutput = z.infer<typeof getTemplateQuestOutputSchema>;
