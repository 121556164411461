import {
  getAnalyticsPathSchema,
  getClaimedQuestStatusCountOutputSchema,
  getRangeAnalyticsQuerySchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const getClaimedQuestsStatusCount = query({
  method: 'GET',
  path: '/status-count',
  pathParams: getAnalyticsPathSchema,
  query: getRangeAnalyticsQuerySchema,
  responses: {
    200: getClaimedQuestStatusCountOutputSchema,
  },
});
