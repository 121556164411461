import {
  updateTokenRewardTransactionBodySchema,
  updateTokenRewardTransactionSchema,
} from '@zealy/schemas';

import { mutation } from '../../utils';

export const updateTokenRewardTransaction = mutation({
  method: 'PATCH',
  path: '/:tokenRewardId',
  body: updateTokenRewardTransactionBodySchema,
  responses: {
    200: updateTokenRewardTransactionSchema,
  },
});
