import { z } from 'zod';

export const banMemberBodySchema = z.object({
  reason: z.string().optional(),
});

export const banMemberPathSchema = z.object({
  subdomain: z.string(),
  userId: z.string().uuid(),
});

export const banMemberInputSchema = banMemberBodySchema.merge(banMemberPathSchema);

export type BanMemberInput = z.infer<typeof banMemberInputSchema>;
