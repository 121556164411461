import { verifyTonProofBodySchema, verifyTonProofOutputSchema } from '@zealy/schemas';

import { mutation } from '../../utils';

export const verifyTonProof = mutation({
  method: 'POST',
  path: '/verify-ton-proof',
  body: verifyTonProofBodySchema,
  responses: {
    200: verifyTonProofOutputSchema,
  },
});
