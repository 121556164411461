import { z } from 'zod';

export const twitterOAuth2CallbackQuerySchema = z.object({
  oauth_token: z.string(),
  oauth_verifier: z.string(),
});

export const twitterOAuth2CallbackInputSchema = twitterOAuth2CallbackQuerySchema;

export type TwitterOAuth2CallbackInput = z.infer<typeof twitterOAuth2CallbackInputSchema>;
