import type { FetchOptions } from 'ofetch';
import Cookies from 'js-cookie';

import type { ClientInferRequest, ClientInferResponseBody, contract } from '@zealy/contracts';

import { apiV2 } from '../services';
import { api } from '../services/api';
import { config } from '../services/config';
import { getMe } from '../services/utils';

export const isUserConnected = () => !!Cookies.get('user_metadata');

// TODO: This should return types from API
export type AccountType = 'wallet' | 'discord' | 'twitter' | 'email' | 'tiktok';

export interface IUserAccount {
  id: string;
  userId: string;
  accountType: AccountType;
  authentification: string;
  createdAt: string;
  updatedAt: string;
  tokenStatus?: 'valid' | 'invalid';
  verified?: boolean;
}

export type User = ClientInferResponseBody<typeof contract.user.get, 200>;

export const getUser = async (id: string): Promise<User> => {
  const userId = id === 'me' ? getMe() : id;

  const res = await apiV2.user.get({
    params: { id: userId! },
    cache: id === 'me' ? 'no-store' : undefined,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getUser failed');
  }
};
export type CommunityMember = ClientInferResponseBody<typeof contract.communityMember.get, 200>;

export const getCommunityMember = async (
  id: string,
  subdomain?: string,
): Promise<CommunityMember> => {
  const userId = id === 'me' ? getMe() : id;
  const res = await apiV2.communityMember.get({
    params: { subdomain: subdomain!, userId: userId! },
    cache: 'no-store',
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getCommunityMember failed');
  }
};

export type CreateUser = ClientInferRequest<typeof contract.user.create>['body'];

export const createUser = async (body: CreateUser) => {
  const res = await apiV2.user.create({
    body,
  });

  if (res.status === 200) {
    return res.body;
  }

  if (res.status === 400) {
    throw new Error(res.body.message);
  }

  throw new Error('createUser failed');
};

export const createUserV2 = async (body: { name: string }) => {
  const res = await apiV2.user.createV2({
    body,
  });

  if (res.status === 200) {
    return res.body;
  }

  if (res.status === 400) {
    throw new Error(res.body.message);
  }

  throw new Error('createUser failed');
};

export interface IUpdateUser {
  username?: string;
  avatar?: File;
  displayedInformation?: string[];
  goal?: string;
  address?: string;
  blockchain?: string;
  city?: string;
  country?: string;
  referrer?: string;
  interests?: string;
}

export const updateAuthenticatedUser = async (data: IUpdateUser) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  const response = await api.patch<User>('/users/me', {
    body: formData,
  });
  return response;
};

export const deleteAuthenticatedUser = async () => {
  return api.delete('/users/me');
};

export const deleteUserAccount = async (userAccountId: string) => {
  const response = await api.delete<User>(`/users/me/accounts/${userAccountId}`);
  return response;
};

export type Activity = {
  id: string;
  userId: string;
  title: string;
  type: 'quest_success' | 'quest_failure' | 'received_xp' | 'lost_xp';
  createdAt: string;
  read: boolean;
  data?: [number, string];
};

export const getActivity = async ({
  userId,
  subdomain,
  page = 0,
  limit = 40,
  options,
}: {
  userId: string;
  subdomain: string;
  page?: number;
  limit?: number;
  options?: FetchOptions<'json'>;
}) => {
  const { activity } = await api<{ activity: Activity[] }>(
    `/communities/${subdomain}/users/${userId}/activity?page=${page}&limit=${limit}`,
    options,
  );

  return {
    results: activity,
    nextPage: page + 1,
    hasNextPage: activity.length >= limit,
  };
};

export type Invite = ClientInferResponseBody<
  typeof contract.communityMember.listInvites,
  200
>['data'][number];

export const getInvites = async ({
  userId,
  subdomain,
  status,
  invitesQuestId,
  pageParam = 1,
  limit = 30,
}: {
  userId: string;
  subdomain: string;
  status: Invite['status'];
  invitesQuestId?: string;
  pageParam?: number;
  limit?: number;
}) => {
  const res = await apiV2.communityMember.listInvites({
    params: {
      subdomain,
      userId,
    },
    query: {
      status,
      invitesQuestId,
      page: pageParam,
      page_size: limit,
    },
  });

  if (res.status === 200) {
    const { data } = res.body;

    return {
      results: data,
      nextPage: pageParam + 1,
      hasNextPage: data.length === limit,
    };
  } else {
    // handle error here
    throw new Error('listInvites fail');
  }
};

export type BanUser = ClientInferRequest<typeof contract.communityMember.ban>['body'];

export const banUser = async (subdomain: string, userId: string, body: BanUser) => {
  const res = await apiV2.communityMember.ban({
    params: {
      subdomain,
      userId,
    },
    body,
  });

  if (res.status !== 200) {
    // handle error here
    throw new Error('banUser failed');
  }
};

export const unBanUser = async (subdomain: string, userId: string) =>
  api.delete(`/communities/${subdomain}/users/${userId}/ban`);

export const updateUserRole = async (
  userId: string,
  subdomain: string,
  role: CommunityMember['role'],
) =>
  api.post(`/communities/${subdomain}/members/change-role`, {
    body: {
      role,
      memberId: userId,
    },
  });

type UploadFileInput = {
  formData: FormData;
  onProgress: (ev: ProgressEvent<EventTarget>) => void;
  signal?: AbortSignal;
  url?: string;
};

export const uploadUserFile = ({
  formData,
  onProgress,
  signal,
  url = `${config.baseURL}/files`,
}: UploadFileInput) => {
  return new Promise<{ url: string }>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('post', url, true);
    xhr.withCredentials = true;
    xhr.responseType = 'json';
    xhr.upload.onprogress = onProgress;
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(xhr.response);
        }
      }
    };
    signal?.addEventListener('abort', () => {
      xhr.abort();
      reject();
    });
    xhr.onerror = reject;
    xhr.send(formData);
  });
};

export const deleteUserFile = (url: string) =>
  api.delete(`${config.baseURL}/files`, {
    body: {
      url,
    },
  });

export const getUserByField = async ({
  field,
  value,
}: {
  field: keyof Pick<User, 'name'>;
  value: string;
}) => {
  const { status, body } = await apiV2.user.getUserByField({
    query: {
      [field]: value,
    },
  });

  if (status !== 200) {
    throw new Error();
  }

  return body;
};
