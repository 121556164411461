import { z } from 'zod';

export const tiktokOAuth2CallbackQuerySchema = z.object({
  scopes: z.string(),
  code: z.string(),
  state: z.string(),
  error: z.string().optional(),
  error_description: z.string().optional(),
});

export const tiktokOAuth2CallbackInputSchema = tiktokOAuth2CallbackQuerySchema;

export type TiktokOAuth2CallbackInput = z.infer<typeof tiktokOAuth2CallbackInputSchema>;
