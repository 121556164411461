import { getAnalyticsPathSchema, getTotalClaimedQuestsOutputSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getTotalClaimedQuests = query({
  method: 'GET',
  path: '/total-claimed-quests',
  pathParams: getAnalyticsPathSchema,
  responses: {
    200: getTotalClaimedQuestsOutputSchema,
  },
});
