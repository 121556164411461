import { z } from 'zod';

export const deleteCommunityWebhookPathSchema = z.object({
  webhookId: z.string().uuid(),
  subdomain: z.string(),
});

export const deleteCommunityWebhookInputSchema = deleteCommunityWebhookPathSchema;

export type DeleteCommunityWebhookInput = z.infer<typeof deleteCommunityWebhookInputSchema>;
