import { listTemplateModulesOutputSchema, listTemplateModulesPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const listTemplateModules = query({
  method: 'GET',
  path: '/modules',
  pathParams: listTemplateModulesPathSchema,
  responses: {
    200: listTemplateModulesOutputSchema,
  },
});
