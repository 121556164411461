import { z } from 'zod';

export const checkMemberBodySchema = z.object({
  userId: z.string().uuid(),
});

export const checkMemberPathSchema = z.object({
  subdomain: z.string(),
});

export const checkMemberInputSchema = checkMemberPathSchema.merge(checkMemberBodySchema);

export type CheckMemberInput = z.infer<typeof checkMemberBodySchema>;
