import { z } from 'zod';

export const DEFAULT_GET_LEADERBOARD_LIMIT = 50;

export const getLeaderboardQuerySchema = z.object({
  sprintId: z.string().optional(),

  page: z.coerce.number().min(1).default(1),
  limit: z.coerce.number().max(1000).default(DEFAULT_GET_LEADERBOARD_LIMIT),
});

export const getLeaderboardPathSchema = z.object({
  subdomain: z.string(),
});

export const getLeaderboardInputSchema = getLeaderboardQuerySchema.merge(getLeaderboardPathSchema);

export const getLeaderboardOutputSchema = z.object({
  data: z.array(
    z.object({
      userId: z.string().uuid(),
      xp: z.number(),
      name: z.string().nullable(),
      avatar: z.string().url().nullable(),
      ethAddress: z.string().optional().nullable(),
      address: z.string().optional().nullable(),
      twitterId: z.string().nullable().optional(),
      twitterUsername: z.string().nullable().optional(),
      discordHandle: z.string().nullable().optional(),
      discordId: z.string().nullable().optional(),
      connectedWallet: z.string().nullable().optional(),
      numberOfQuests: z.number(),
      email: z.string().nullable().optional(),
    }),
  ),
  totalPages: z.number(),
  page: z.number(),
  totalRecords: z.number(),
  status: z.enum(['computed', 'resyncing', 'outdated']).optional(),
});

export type GetLeaderboardInput = z.infer<typeof getLeaderboardInputSchema>;

export type GetLeaderboardOutput = z.infer<typeof getLeaderboardOutputSchema>;
