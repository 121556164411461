import { z } from 'zod';

export const getSubscriptionStatusPathSchema = z.object({
  subdomain: z.string(),
});

export const getSubscriptionStatusInputSchema = getSubscriptionStatusPathSchema;

export type GetSubscriptionStatusInput = z.infer<typeof getSubscriptionStatusInputSchema>;

export const getSubscriptionStatusOutputSchema = z.object({
  isLimitReached: z.boolean(),
  resetAt: z.string().datetime(),
  canAccessFreeTrial: z.boolean(),
});

export type GetSubscriptionStatusOutput = z.infer<typeof getSubscriptionStatusOutputSchema>;
