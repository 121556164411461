import { z } from 'zod';

import { Location } from '../common';

export const redirectOAuth2QuerySchema = z.object({
  subdomain: z.string(),
  type: z.string(),
  invitationId: z.string().optional(),
  location: Location.optional(),
});

export const redirectOAuth2InputSchema = redirectOAuth2QuerySchema;

export type RedirectOAuth2Input = z.infer<typeof redirectOAuth2InputSchema>;
