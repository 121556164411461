import { z } from 'zod';

import { BLOCKCHAIN_NETWORKS, isValidUrl } from '@zealy/utils';

import {
  augmentedDiscordTaskSchema,
  augmentedNftTaskSchema,
  augmentedTelegramTaskSchema,
  augmentedTokenTaskSchema,
  augmentedTweetReactTaskSchema,
  augmentedTwitterFollowTaskSchema,
  augmentedTwitterSpaceTaskSchema,
  baseApiTaskSchema,
  baseDateTaskSchema,
  baseId,
  baseInstructionSettingsSchema,
  baseNumberTaskSchema,
  baseQuestionTaskSettingsSchema,
  baseQuizTaskSchema,
  baseRewardMethodSchema,
  baseTextTaskSchema,
  baseTwitterSpaceTaskSchema,
  baseTwitterSpaceTaskSettingsSchema,
  baseUrlTaskSchema,
  conditionOperatorSchema,
  conditionSchema,
  descriptionSchema,
  discordTaskSchema,
  fileTaskSchema,
  identificationsApiTaskSchema,
  invitesTaskSchema,
  nftTaskSchema,
  onChainTaskSchema,
  opinionTaskSchema,
  partnershipTaskSchema,
  pollTaskSchema,
  positiveIntSchema,
  recurrenceSchema,
  retryAfterSchema,
  rewardOtherSchema,
  rewardRoleInputSchema,
  rewardRoleOutputSchema,
  rewardTypeSchema,
  rewardXPSchema,
  telegramTaskSchema,
  tiktokTaskSchema,
  tokenTaskSchema,
  tweetReactTaskSchema,
  tweetTaskSchema,
  twitterFollowTaskSchema,
  visitLinkTaskSchema,
} from './baseQuest';

const rewardNFTSchema = z.object({
  type: z.literal(rewardTypeSchema.enum.nft),
  name: z.string().default('').nullish(),
  symbol: z.string(),
  maxSupply: z.preprocess(a => {
    if (typeof a === 'string' && a) {
      return parseInt(z.string().parse(a), 10);
    }
    return a;
  }, positiveIntSchema),
  value: z.string().uuid(),
  imageUrl: z.string().url(),
});

const rewardInputSchema = z
  .discriminatedUnion('type', [
    rewardXPSchema,
    rewardOtherSchema,
    rewardRoleInputSchema,
    rewardNFTSchema,
  ])
  .and(z.object({ method: baseRewardMethodSchema.optional() }));

const rewardOutputSchema = z.discriminatedUnion('type', [
  rewardXPSchema,
  rewardOtherSchema,
  rewardRoleOutputSchema,
  rewardNFTSchema,
]);

export const textAutoValidatedSettingsSchema = z.discriminatedUnion('autoValidated', [
  z.object({
    autoValidated: z.literal(false),
  }),
  z.object({
    autoValidated: z.literal(true),
    correctAnswer: z.string().optional(),
  }),
]);

export const numberAutoValidatedSettingsSchema = z.discriminatedUnion('autoValidated', [
  z.object({
    autoValidated: z.literal(false),
  }),
  z.object({
    autoValidated: z.literal(true),
    correctAnswer: z.number().or(
      z
        .nan()
        .transform(() => undefined)
        .optional(),
    ),
  }),
]);

export const urlAutoValidatedSettingsSchema = z.discriminatedUnion('autoValidated', [
  z.object({
    autoValidated: z.literal(false),
  }),
  z.object({
    autoValidated: z.literal(true),
    correctAnswer: z.union([z.literal(''), z.string().url()]),
  }),
]);

export const dateAutoValidatedSettingsSchema = z.discriminatedUnion('autoValidated', [
  z.object({
    autoValidated: z.literal(false),
  }),
  z.object({
    autoValidated: z.literal(true),
    correctAnswer: z.string().datetime().optional(),
  }),
]);

export const apiTaskSchema = baseApiTaskSchema.extend({
  settings: baseInstructionSettingsSchema
    .extend({
      endpoint: z.string().url().startsWith('https', 'Endpoint must start with https'),
      apiKey: z.string().optional(),
      identifications: identificationsApiTaskSchema,
      network: z.enum(BLOCKCHAIN_NETWORKS).optional(),
      redirectUrl: z.string().optional(),
    })
    .refine(
      data => !(data.identifications?.includes('zealy-connect') && !isValidUrl(data.redirectUrl)),
      {
        message: 'Required if Zealy Connect is enabled',
        path: ['redirectUrl'],
      },
    ),
});

export const textTaskSchema = baseTextTaskSchema.extend({
  settings: baseInstructionSettingsSchema.and(textAutoValidatedSettingsSchema),
});

export const urlTaskSchema = baseUrlTaskSchema.extend({
  settings: baseInstructionSettingsSchema.and(urlAutoValidatedSettingsSchema),
});

export const dateTaskSchema = baseDateTaskSchema.extend({
  settings: baseInstructionSettingsSchema.and(dateAutoValidatedSettingsSchema),
});

export const numberTaskSchema = baseNumberTaskSchema.extend({
  settings: baseInstructionSettingsSchema.and(numberAutoValidatedSettingsSchema),
});

// export const quizImageTaskSettingsSchema = z.discriminatedUnion('withImage', [
//   z.object({
//     withImage:  z.literal(false),
//     options: z
//       .array(
//         z.object({
//           label: z.string(),
//           correctAnswer: z.boolean().optional(),
//         }),
//       )
//       .min(1),
//   }),
//   z.object({
//     withImage: z.literal(true),
//     options: z
//       .array(
//         z.object({
//           imageUrl: s3UrlSchema,
//           label: z.string(),
//           correctAnswer: z.boolean().optional(),
//         }),
//       )
//       .min(1),
//   }),
// ]);
export const quizImageTaskSettingsSchema = z.object({
  withImage: z.literal(false).optional().default(false),
  options: z
    .array(
      z.object({
        label: z.string(),
        correctAnswer: z.boolean().optional(),
      }),
    )
    .min(1),
});

export const quizTaskSchema = baseQuizTaskSchema.extend({
  settings: baseQuestionTaskSettingsSchema.and(quizImageTaskSettingsSchema),
});

export const postQuestTwitterSpaceTaskSchema = baseTwitterSpaceTaskSchema.extend({
  settings: baseTwitterSpaceTaskSettingsSchema.extend({
    password: z.string().optional(),
  }),
});

export const taskInputSchema = z.discriminatedUnion('type', [
  partnershipTaskSchema,
  onChainTaskSchema,
  nftTaskSchema,
  tokenTaskSchema,
  apiTaskSchema,
  textTaskSchema,
  discordTaskSchema,
  urlTaskSchema,
  telegramTaskSchema,
  invitesTaskSchema,
  quizTaskSchema,
  visitLinkTaskSchema,
  fileTaskSchema,
  dateTaskSchema,
  numberTaskSchema,
  pollTaskSchema,
  opinionTaskSchema,
  twitterFollowTaskSchema,
  postQuestTwitterSpaceTaskSchema,
  tweetReactTaskSchema,
  tweetTaskSchema,
  tiktokTaskSchema,
]);

export const augmentedTaskSchema = z.intersection(
  baseId,
  z.discriminatedUnion('type', [
    partnershipTaskSchema,
    onChainTaskSchema,
    apiTaskSchema,
    textTaskSchema,
    urlTaskSchema,
    invitesTaskSchema,
    quizTaskSchema,
    visitLinkTaskSchema,
    fileTaskSchema,
    dateTaskSchema,
    numberTaskSchema,
    pollTaskSchema,
    opinionTaskSchema,
    tweetTaskSchema,
    tiktokTaskSchema,
    tokenTaskSchema.merge(augmentedTokenTaskSchema),
    nftTaskSchema.merge(augmentedNftTaskSchema),
    postQuestTwitterSpaceTaskSchema.merge(augmentedTwitterSpaceTaskSchema),
    tweetReactTaskSchema.merge(augmentedTweetReactTaskSchema),
    telegramTaskSchema.merge(augmentedTelegramTaskSchema),
    discordTaskSchema.merge(augmentedDiscordTaskSchema),
    twitterFollowTaskSchema.merge(augmentedTwitterFollowTaskSchema),
  ]),
);

export const baseQuestInputSchema = z.object({
  categoryId: z.string().uuid(),
  name: z.string().max(100),
  published: z.boolean(),
  recurrence: recurrenceSchema,
  conditionOperator: conditionOperatorSchema, // .optional() ?
  retryAfter: retryAfterSchema.default(0).nullish(),
  description: descriptionSchema.nullable(),
  conditions: z.array(conditionSchema),
  rewards: z.array(rewardInputSchema),
  templateId: z.string().uuid().optional(),
  position: z.number().min(0).max(5000).optional(),
  claimLimit: z.preprocess(a => {
    if (typeof a === 'string' && a) {
      return parseInt(z.string().parse(a), 10);
    }
    return a;
  }, z.number().min(0).max(2147483647).default(0)),
  sprintId: z.string().uuid().optional(),
});

export const postQuestBaseInputSchema = baseQuestInputSchema.merge(
  z.object({
    tasks: z.array(taskInputSchema),
  }),
);

interface IShared {
  rewards?: z.infer<typeof postQuestBaseInputSchema>['rewards'];
  conditions?: z.infer<typeof postQuestBaseInputSchema>['conditions'];
  claimLimit?: z.infer<typeof postQuestBaseInputSchema>['claimLimit'];
}

export const additionalQuestCheck = <O extends IShared, T extends z.ZodTypeDef, I>(
  schema: z.ZodType<O, T, I>,
) =>
  schema.refine(
    data => {
      const raffleRewardIndex = (data?.rewards ?? []).findIndex(
        reward => reward?.method?.type === 'raffle',
      );

      if (raffleRewardIndex >= 0) {
        const isClaimLimitSet = data.claimLimit !== 0;
        const hasBeforeDateCondition = (data?.conditions ?? []).some(
          condition => condition.type === 'date' && condition.operator === '<',
        );

        return isClaimLimitSet || hasBeforeDateCondition;
      }
      return true;
    },
    () => ({
      message: 'missingClaimLimitOrBeforeDateCondition',
      path: ['root'],
    }),
  );

export const postQuestInputSchema = additionalQuestCheck(postQuestBaseInputSchema);

export const questSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  recurrence: recurrenceSchema,
  description: z.object({}).nullable(),
  retryAfter: z.number().nullable(),
  archived: z.boolean(),
  published: z.boolean(),
  categoryId: z.string().uuid(),
  conditionOperator: conditionOperatorSchema,
  conditions: z.array(conditionSchema),
  rewards: z.array(rewardOutputSchema),
  tasks: z.array(augmentedTaskSchema),
  claimLimit: z.number().min(0).max(2147483647),
  claimCounter: z.number().min(0).max(2147483647).optional(),
});

export type RewardInput = z.infer<typeof rewardInputSchema>;
export type RewardOutput = z.infer<typeof rewardOutputSchema>;

export type PostQuestNFTRewardInput = z.infer<typeof rewardNFTSchema>;
export type AdminTaskOutput = z.infer<typeof augmentedTaskSchema>;
export type TaskInput = z.infer<typeof taskInputSchema>;
export type AdminQuest = z.infer<typeof questSchema>;
