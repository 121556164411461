import { z } from 'zod';

export const getReferralCountOutputSchema = z.object({
  isComputed: z.boolean(),
  data: z
    .object({
      invitedUsers: z.number(),
      nonInvitedUsers: z.number(),
    })
    .nullable(),
});
