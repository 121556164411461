import {
  none,
  verifyZealyConnectCallbackPathSchema,
  verifyZealyConnectCallbackQuerySchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const verifyZealyConnectCallback = query({
  method: 'GET',
  path: '/:questId/task/:taskId/zealy_connect/callback',
  pathParams: verifyZealyConnectCallbackPathSchema,
  query: verifyZealyConnectCallbackQuerySchema,
  responses: {
    302: none,
  },
});
