import {
  listInvitesPathSchema,
  listInvitesPublicApiOutputSchema,
  listInvitesQuerySchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const listInvites = query({
  summary: 'List invites for a user',
  method: 'GET',
  path: '/:userId/invites',
  pathParams: listInvitesPathSchema,
  query: listInvitesQuerySchema,
  responses: {
    200: listInvitesPublicApiOutputSchema,
  },
});
