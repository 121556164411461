import { sendOtpBodySchema } from '@zealy/schemas';

import { mutation, noBody } from '../../utils';

export const sendOtp = mutation({
  method: 'POST',
  path: '/otp/send',
  body: sendOtpBodySchema,
  responses: {
    200: noBody(),
  },
});
