import { generateTonProofOutputSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const generateTonPayload = query({
  method: 'GET',
  path: '/ton-payload',
  responses: {
    200: generateTonProofOutputSchema,
  },
});
