import { updateUserEmailBodySchema, updateUserEmailPathSchema } from '@zealy/schemas';

import { mutation, noBody } from '../../utils';

export const updateUserEmail = mutation({
  method: 'POST',
  path: '/:id/update-email',
  pathParams: updateUserEmailPathSchema,
  body: updateUserEmailBodySchema,
  responses: {
    200: noBody(),
  },
});
