import { z } from 'zod';

const categorySchema = z.enum(['upcoming', 'all', 'featured']);

export const listCommunityQuerySchema = z.object({
  category: categorySchema,
});

export const listCommunityInputSchema = listCommunityQuerySchema;

export const listCommunityOutputSchema = z.object({
  communities: z.array(
    z.object({
      id: z.string(),
      name: z.string().nullable(),
      createdAt: z.string().datetime().nullable(),
      launchDate: z.string().datetime().nullable(),
      subdomain: z.string().nullable(),
      logo: z.string().nullable(),
      image: z.string().nullable(),
      blockchain: z.string(),
      description: z.string().nullable(),
      website: z.string().nullable(),
      twitter: z.string().nullable(),
      discord: z.string().nullable(),
      flag: z.boolean(),
      opensea: z.string().nullable(),
      requiredFields: z
        .object({
          fillEmail: z.boolean(),
          fillWallet: z.boolean(),
          linkDiscord: z.boolean(),
          linkTwitter: z.boolean(),
        })
        .nullable(),
      visibility: z.enum(['public', 'private']),
      certified: z.boolean(),
      sector: z.string().nullable(),
      type: z.string().nullable(),
      whiteLabelConfig: z.record(z.unknown()).nullable(),
      minimumRequiredXp: z.number(),
      v2: z.boolean().nullable(),
      totalMembers: z.number(),
      totalTwitterFollowers: z.number(),
      totalDiscordMembers: z.number(),
      totalQuests: z.number(),
    }),
  ),
});

export type CommunityCategory = z.infer<typeof categorySchema>;
export type ListCommunityOutput = z.infer<typeof listCommunityOutputSchema>;
