import {
  partialCommunities,
  partnershipsParamsSchema,
  searchPartnershipCommunitiesQuerySchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const searchPartnershipCommunity = query({
  method: 'GET',
  path: '/search',
  pathParams: partnershipsParamsSchema,
  query: searchPartnershipCommunitiesQuerySchema,
  responses: {
    200: partialCommunities,
  },
});
