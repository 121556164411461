import { setRoleBodySchema, setRolePathSchema } from '@zealy/schemas';

import { mutation, noBody } from '../../utils';

export const setRole = mutation({
  method: 'POST',
  path: '/:userId/set-role',
  pathParams: setRolePathSchema,
  body: setRoleBodySchema,
  responses: {
    200: noBody(),
  },
});
