import z from 'zod';

// export domain schemas
export * from './rewards';
export * from './userApiKeyHash';
export * from './subscription';
export * from './communityWebhook';
export * from './userCommunity';
export * from './userAccount';
export * from './blockchain';
export * from './community';
export * from './user';
export * from './getHealth';
export * from './auth';
export * from './leaderboard';
export * from './leaderboardSprint';
export * from './reviews';
export * from './dev';
export * from './notification';
export * from './quest';
export * from './communityMember';
export * from './analytics';
export * from './template';
export * from './invitation';
export * from './partnership';
export * from './module';
export * from './promotionBooking';
export * from './communitySecret';
export * from './aiCommunityManager';
export * from './tokenReward';

// use this in contracts when there is no input or output
export const none = z.void();
