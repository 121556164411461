import { router } from '../../utils';
import { createReview } from './createReview';
import { getReview } from './getReview';
import { getUserStats } from './getUserStats';
import { listReviewEvents } from './listReviewEvents';
import { listReviews } from './listReviews';

export const publicReviewContract = router(
  {
    get: getReview,
    getUserStats: getUserStats,
    list: listReviews,
    listEvents: listReviewEvents,
    create: createReview,
  },
  {
    pathPrefix: '/communities/:subdomain/reviews',
  },
);
