import { z } from 'zod';

export const getDiscordMembersOutputSchema = z.object({
  isComputed: z.boolean(),
  data: z
    .array(
      z.object({
        date: z.string(),
        value: z.number(),
      }),
    )
    .nullable(),
});
