import { listReviewEventsOutputSchema, listReviewEventsPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const listReviewEvents = query({
  method: 'GET',
  path: '/:id/events',
  pathParams: listReviewEventsPathSchema,
  responses: {
    200: listReviewEventsOutputSchema,
  },
});
