import { z } from 'zod';

export const regenerateCommunityWebhookSecretPathSchema = z.object({
  subdomain: z.string(),
  webhookId: z.string().uuid(),
});

export const regenerateCommunityWebhookSecretInputSchema =
  regenerateCommunityWebhookSecretPathSchema;

export const regenerateCommunityWebhookSecretOutputSchema = z.object({
  secret: z.string(),
});

export type RegenerateCommunityWebhookSecretInput = z.infer<
  typeof regenerateCommunityWebhookSecretInputSchema
>;

export type RegenerateCommunityWebhookSecretOutput = z.infer<
  typeof regenerateCommunityWebhookSecretOutputSchema
>;
