import { z } from 'zod';

import { communityWebhookEventType } from './common';

export const updateCommunityWebhookBodySchema = z.object({
  name: z.string().min(1).optional(),
  active: z.boolean().optional(),
  uri: z.string().url().optional(),
  events: z.array(communityWebhookEventType).min(1).optional(),
});

export const updateCommunityWebhookPathSchema = z.object({
  webhookId: z.string().uuid(),
  subdomain: z.string(),
});

export const updateCommunityWebhookInputSchema = updateCommunityWebhookPathSchema.merge(
  updateCommunityWebhookBodySchema,
);

export type UpdateCommunityWebhookInput = z.infer<typeof updateCommunityWebhookInputSchema>;
