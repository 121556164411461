import { getReviewAlertsOutputSchema, getReviewAlertsPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getReviewAlerts = query({
  method: 'GET',
  path: '/:id/alerts',
  pathParams: getReviewAlertsPathSchema,
  responses: {
    200: getReviewAlertsOutputSchema,
  },
});
