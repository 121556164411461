import { getMemberOutputSchema, getMemberPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getMember = query({
  method: 'GET',
  path: '/:userId',
  pathParams: getMemberPathSchema,
  responses: {
    200: getMemberOutputSchema,
  },
});
