import z from 'zod';

const MAINNET_NETWORK = '-239';
const TESTNET_NETWORK = '-3';

export const verifyTonProofBodySchema = z.object({
  address: z.string(),
  network: z.enum([MAINNET_NETWORK, TESTNET_NETWORK]),
  public_key: z.string(),
  proof: z.object({
    timestamp: z
      .union([z.number(), z.string()])
      .transform(v => (typeof v === 'string' ? parseInt(v) : v)),
    domain: z.object({
      lengthBytes: z.number(),
      value: z.string(),
    }),
    payload: z.string(),
    signature: z.string(),
    state_init: z.string(),
  }),
});

export const verifyTonProofOutputSchema = z.boolean();

export type VerifyTonProofInput = z.infer<typeof verifyTonProofBodySchema>;
