import { z } from 'zod';

export const getCommunityWebhookEventPathSchema = z.object({
  subdomain: z.string(),
  webhookId: z.string().uuid(),
  eventId: z.string().uuid(),
});

export const getCommunityWebhookEventInputSchema = getCommunityWebhookEventPathSchema;

export const getCommunityWebhookEventOutputSchema = z.object({
  id: z.string().uuid(),
  webhookId: z.string().uuid(),
  status: z.string(),
  createdAt: z.string().datetime().nullable(),
  updatedAt: z.string().datetime().nullable(),
  retryAt: z.string().datetime().nullable(),
  eventType: z.string(),
  tries: z.number(),
  data: z.any(),
  error: z
    .object({
      status: z.number().optional(),
      message: z.string().optional(),
    })
    .nullable(),
});

export type GetCommunityWebhookEventInput = z.infer<typeof getCommunityWebhookEventInputSchema>;

export type GetCommunityWebhookEventOutput = z.infer<typeof getCommunityWebhookEventOutputSchema>;
