import { z } from 'zod';

export const getUserApiKeyHashInputSchema = z.object({
  // add path params : have to be the same name as path in TRCP
  id: z.string(),
  // add query params
});

export const getUserApiKeyHashOutputSchema = z.object({
  // add response
});

export type GetUserApiKeyHashOutput = z.infer<typeof getUserApiKeyHashOutputSchema>;
