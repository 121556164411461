import { z } from 'zod';

import { dateWithoutTime } from '../common';

export const createPromotionBookingSchema = z.object({
  toCreate: z.array(dateWithoutTime),
  toRemove: z.array(dateWithoutTime),
});

export type CreatePromotionBookingInput = z.infer<typeof createPromotionBookingSchema>;
