import { getBasicInfoOutputSchema, getBasicInfoPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getBasicInfo = query({
  summary: 'Get basic info about a community',
  method: 'GET',
  path: '/:subdomain',
  pathParams: getBasicInfoPathSchema,
  responses: {
    200: getBasicInfoOutputSchema,
  },
});
