import type { FetchOptions } from 'ofetch';
import { ofetch } from 'ofetch';

import type { ApiType, FetchRequest, MappedType, Method, ResponseType } from './utils';
import { config } from './config';
import { clientFactory, forwardedCookie } from './utils';

type APIMethodHandlers = {
  [M in Method]: <T = any, R extends ResponseType = 'json'>(
    url: string,
    options?: Omit<FetchOptions, 'method'>,
  ) => Promise<MappedType<R, T>>;
} & ApiType;

const $fetch = ofetch.create({
  baseURL: config.baseURL,
  headers: config.headers,
  onRequest(context) {
    context.options.baseURL = config.baseURL;
    const cookie = forwardedCookie();
    const apiKey = (context.options as any).apiKey;

    context.options.headers = {
      ...context.options.headers,
      ...config.headers,
      ...(apiKey ? { 'X-Api-Key': apiKey } : {}),
      ...(cookie ? { cookie } : {}),
    };
  },
  credentials: 'include',
});

/**
 * HTTP API Fetch instance
 * @param request
 * @param options
 * @returns
 */
function __api<T = any, R extends ResponseType = 'json'>(
  request: FetchRequest,
  options?: FetchOptions<R>,
): Promise<MappedType<R, T>> {
  return $fetch<T, R>(request, options) as Promise<MappedType<R, T>>;
}

export const api = clientFactory<APIMethodHandlers>(__api, $fetch);
