import { z } from 'zod';

export const getTopReviewersOutputSchema = z.object({
  isComputed: z.boolean(),
  data: z
    .array(
      z.object({
        reviewerId: z.string(),
        name: z.string().nullable(),
        successRate: z.number(),
        value: z.number(),
      }),
    )
    .nullable(),
});
