import { z } from 'zod';

export const listUserAccountInputSchema = z.object({
  // add path params : have to be the same name as path in TRCP
  // add query params
  myQueryParam: z.boolean(),
});

export const listUserAccountOutputSchema = z.array(
  z.object({
    // add response
  }),
);

export type ListUserAccountOutput = z.infer<typeof listUserAccountOutputSchema>;
