import { getRankOutputSchema, getRankPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getUserRank = query({
  method: 'GET',
  path: '/:sprintId/:userId/rank',
  pathParams: getRankPathSchema,
  responses: {
    200: getRankOutputSchema,
  },
});
