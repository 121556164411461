import { z } from 'zod';

export const createEmbeddingBodySchema = z.object({
  question: z.string(),
  answer: z.string(),
});

export const createEmbeddingInputSchema = createEmbeddingBodySchema;

export const createEmbeddingOutputSchema = z.enum(['acknowledged', 'completed']);

export type CreateEmbeddingInput = z.infer<typeof createEmbeddingInputSchema>;
export type CreatedEmbeddingOutput = z.infer<typeof createEmbeddingOutputSchema>;
