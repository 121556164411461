import { z } from 'zod';

export const otpLoginBodySchema = z.object({
  email: z.string().email().max(255),
  otp: z.string().length(6),
});

export const otpLoginInputSchema = otpLoginBodySchema;

export type OtpLoginInput = z.infer<typeof otpLoginInputSchema>;
