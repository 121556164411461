import { z } from 'zod';

export const getBasicInfoPathSchema = z.object({
  subdomain: z.string(),
});

export const getBasicInfoInputSchema = getBasicInfoPathSchema;

export const getBasicInfoOutputSchema = z.object({
  name: z.string(),
  subdomain: z.string(),
  id: z.string(),
  image: z.string().nullable(),
  description: z.string().nullable(),
});

export type GetBasicInfoInput = z.infer<typeof getBasicInfoPathSchema>;

export type GetBasicInfoOutput = z.infer<typeof getBasicInfoOutputSchema>;
