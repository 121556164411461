import { z } from 'zod';

import { getUserPublicOutputSchema } from './getUser';

export const getUserByFieldSchema = z.object({
  name: z.string(),
});

export const getUserByFieldInputSchema = getUserByFieldSchema;
export const getUserByFieldOutputSchema = getUserPublicOutputSchema;
export type GetUserByFieldInput = z.infer<typeof getUserByFieldInputSchema>;
export type GetUserByFieldOutput = z.infer<typeof getUserByFieldOutputSchema>;
