import { z } from 'zod';

import { moduleSchema } from './common';

export const listTemplateModulesPathSchema = z.object({
  subdomain: z.string(),
});

export const groupSchema = z.object({
  id: z.string(),
  name: z.string(),
  modules: z.array(moduleSchema),
});

export const listTemplateModulesOutputSchema = z.object({
  data: z.array(groupSchema),
});

export type ListTemplateModulesOutput = z.infer<typeof listTemplateModulesOutputSchema>;
