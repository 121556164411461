import { z } from 'zod';

export const getRankPathSchema = z.object({
  sprintId: z.string(),
  subdomain: z.string(),
  userId: z.string().uuid(),
});

export const getRankInputSchema = getRankPathSchema;

export const getRankOutputSchema = z.object({
  avatar: z.string().nullable(),
  id: z.string(),
  name: z.string().nullable(),
  rank: z.number().nullable(),
  xp: z.number().default(0),
  numberOfQuests: z.number().default(0),
});

export type GetRankInput = z.infer<typeof getRankInputSchema>;

export type GetRankOutput = z.infer<typeof getRankOutputSchema>;
