import { z } from 'zod';

export const getInvitationPathSchema = z.object({
  id: z.string(),
  subdomain: z.string(),
});

export const getInvitationInputSchema = getInvitationPathSchema;

export const getInvitationOutputSchema = z.object({
  id: z.string(),
  expiresAt: z.string().nullable(),
  role: z.enum(['admin', 'guest', 'reviewer', 'editor']),
  status: z.enum(['valid', 'invalid']),
  user: z.object({
    id: z.string().uuid(),
    name: z.string().nullish(),
    avatar: z.string().nullish(),
  }),
});

export type GetInvitationInput = z.infer<typeof getInvitationInputSchema>;
export type GetInvitationOutput = z.infer<typeof getInvitationOutputSchema>;
