import { router } from '../../utils';
import { createLeaderboardSprint } from './createLeaderboardSprint';
import { deleteLeaderboardSprint } from './deleteLeaderboardSprint';
import { listLeaderboardSprintQuests } from './listLeaderboardSprintQuests';
import { listSprints } from './listSprint';
import { updateLeaderboardSprint } from './updateLeaderboardSprint';

export const leaderboardSprintContract = router({
  create: createLeaderboardSprint,
  delete: deleteLeaderboardSprint,
  listQuests: listLeaderboardSprintQuests,
  update: updateLeaderboardSprint,
  list: listSprints,
});
