import { z } from 'zod';

export const updateUserCommunityBodySchema = z.object({});

export const updateUserCommunityPathSchema = z.object({
  id: z.string().uuid(),
});

export const updateUserCommunityInputSchema = updateUserCommunityPathSchema.merge(
  updateUserCommunityBodySchema,
);

export const updateUserCommunityOutputSchema = z.object({
  // add response
});

export type UpdateUserCommunityInput = z.infer<typeof updateUserCommunityBodySchema>;

export type UpdateUserCommunityOutput = z.infer<typeof updateUserCommunityOutputSchema>;
