import { z } from 'zod';

export const seedClaimsBodySchema = z.object({
  numberOfUsers: z.number().optional(),
  numberOfClaims: z.number().optional(),
  sprintId: z.string().optional(),
  createSprint: z.boolean().optional(),
  subdomain: z.string(),
});

export const seedClaimsInputSchema = seedClaimsBodySchema;

export type SeedClaimsInput = z.infer<typeof seedClaimsInputSchema>;
export type SeedClaimsOutput = void;
