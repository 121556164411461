import { z } from 'zod';

export const deleteLeaderboardSprintQuerySchema = z.object({
  id: z.string().uuid(),
});

export const deleteLeaderboardSprintPathSchema = z.object({
  subdomain: z.string(),
});

export const deleteLeaderboardSprintInputSchema = deleteLeaderboardSprintQuerySchema.merge(
  deleteLeaderboardSprintPathSchema,
);

export type DeleteLeaderboardSprintInput = z.infer<typeof deleteLeaderboardSprintInputSchema>;
