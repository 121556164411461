import {
  createCheckoutSessionUrlBodySchema,
  createCheckoutSessionUrlOutputSchema,
  createCheckoutSessionUrlPathSchema,
} from '@zealy/schemas';

import { mutation } from '../../utils';

export const createCheckoutSessionUrl = mutation({
  method: 'POST',
  path: '',
  pathParams: createCheckoutSessionUrlPathSchema,
  body: createCheckoutSessionUrlBodySchema,
  responses: {
    200: createCheckoutSessionUrlOutputSchema,
  },
});
