import { router } from '../../utils';
import { generateMagicQuest } from './generateMagicQuest';
import { generateMagicQuestSuggestions } from './generateMagicQuestSuggestions';

export const magicQuestContract = router(
  {
    generate: generateMagicQuest,
    generateSuggestions: generateMagicQuestSuggestions,
  },
  {
    pathPrefix: '/communities/:subdomain/magic-quests',
  },
);
