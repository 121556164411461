import { createUserOutputSchema, createUserV2BodySchema } from '@zealy/schemas';

import { mutation } from '../../utils';

export const createUserV2 = mutation({
  method: 'POST',
  path: '/v2',
  body: createUserV2BodySchema,
  responses: {
    200: createUserOutputSchema,
  },
});
