import {
  generateMagicQuestSuggestionsBodySchema,
  generateMagicQuestSuggestionsOutputSchema,
  generateMagicQuestSuggestionsPathSchema,
} from '@zealy/schemas';

import { mutation } from '../../utils';

export const generateMagicQuestSuggestions = mutation({
  method: 'POST',
  path: '/get-suggestions',
  pathParams: generateMagicQuestSuggestionsPathSchema,
  body: generateMagicQuestSuggestionsBodySchema,
  responses: {
    200: generateMagicQuestSuggestionsOutputSchema,
  },
});
