import {
  listCommunitySprintsOutputSchema,
  listCommunitySprintsPathSchema,
  listCommunitySprintsQuerySchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const listSprints = query({
  summary: 'List sprints for a community',
  method: 'GET',
  path: '/sprint',
  pathParams: listCommunitySprintsPathSchema,
  query: listCommunitySprintsQuerySchema,
  responses: {
    200: listCommunitySprintsOutputSchema,
  },
});
