import { router } from '../../utils';
import { getQuestRewards } from './getQuestRewards';

export const rewardContract = router(
  {
    get: getQuestRewards,
  },
  {
    pathPrefix: '/communities/:subdomain/quests/:questId/rewards',
  },
);
