import { router } from '../../utils';
import { createCommunitySecret } from './createCommunitySecret';
import { listCommunitySecret } from './listCommunitySecret';

export const communitySecretContract = router(
  {
    createCommunitySecret,
    listCommunitySecret,
  },
  {
    pathPrefix: '/communities/:subdomain/secret',
  },
);
