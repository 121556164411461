import { z } from 'zod';

import { tokenTaskSettingsNetworkSchema } from '@zealy/utils';

export const getTokenContractMetadataPathSchema = tokenTaskSettingsNetworkSchema;

export const getTokenContractMetadataInputSchema = getTokenContractMetadataPathSchema;

export const getTokenContractMetadataOutputSchema = z.object({
  name: z.string().nullable(),
  symbol: z.string().nullable(),
  decimals: z.number().nullable(),
  logo: z.string().nullable(),
});

export type GetContractMetadataInput = z.infer<typeof getTokenContractMetadataInputSchema>;

export type GetContractMetadataOutput = z.infer<typeof getTokenContractMetadataOutputSchema>;
