import {
  listTaskValidationsOutputSchema,
  listTaskValidationsPathSchema,
  listTaskValidationsQuerySchema,
} from '@zealy/schemas/src/quest/listTaskValidations';

import { query } from '../../utils';

export const listTaskValidations = query({
  method: 'GET',
  path: '/:questId/task/:taskId/validations',
  pathParams: listTaskValidationsPathSchema,
  query: listTaskValidationsQuerySchema,
  responses: {
    200: listTaskValidationsOutputSchema,
  },
});
