import { getReferralCodeOutputSchema, getReferralCodePathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getReferralCode = query({
  summary: 'Get the referral code for a user',
  method: 'GET',
  path: '/:userId/referral-code',
  pathParams: getReferralCodePathSchema,
  responses: {
    200: getReferralCodeOutputSchema,
  },
});
