import { z } from 'zod';

export const getCommunityInputSchema = z.object({
  // add path params : have to be the same name as path in TRCP
  subdomain: z.string(),
  // add query params
});

export const getCommunityOutputSchema = z.object({
  // add response
});

export type GetCommunityOutput = z.infer<typeof getCommunityOutputSchema>;
