import {
  generateRedirectUrlForApiQuestTaskOutputSchema,
  generateRedirectUrlForApiQuestTaskPathSchema,
} from '@zealy/schemas';

import { mutation } from '../../utils';

export const generateRedirectUrlForApiQuestTask = mutation({
  method: 'POST',
  path: '/:questId/task/:taskId/generate_redirect_url',
  pathParams: generateRedirectUrlForApiQuestTaskPathSchema,
  body: null,
  responses: {
    200: generateRedirectUrlForApiQuestTaskOutputSchema,
  },
});
