import { getMemberPathSchema, getMemberPublicApiOutputSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getMember = query({
  summary: 'Get information about a user',
  method: 'GET',
  path: '/:userId',
  pathParams: getMemberPathSchema,
  responses: {
    200: getMemberPublicApiOutputSchema,
  },
});
