import { z } from 'zod';

export const getUserAccountInputSchema = z.object({
  // add path params : have to be the same name as path in TRCP
  id: z.string(),
  // add query params
});

export const getUserAccountOutputSchema = z.object({
  // add response
});

export type GetUserAccountOutput = z.infer<typeof getUserAccountOutputSchema>;
