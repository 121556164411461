import { z } from 'zod';

const communitySecretSchema = z.object({
  secret: z.string(),
  prefix: z.string(),
  createdAt: z.string(),
  deletedAt: z.string().nullable(),
});

export const createCommunitySecretOutputSchema = communitySecretSchema;
