import { z } from 'zod';

import { mutation } from '../../utils';

export const resetClaims = mutation({
  method: 'POST',
  path: '/reset-claims',
  body: z.object({
    questId: z.string().optional(),
  }),
  responses: {
    200: z.array(z.string()),
  },
});
