import { z } from 'zod';

export const vectorStoreSearchBodySchema = z.object({
  query: z.string(),
});

export const vectorStoreSearchInputSchema = vectorStoreSearchBodySchema;

export const vectorStoreSearchOutputSchema = z.object({
  results: z.array(
    z.object({
      text: z.string(),
      id: z.string(),
    }),
  ),
});

export type VectorStoreSearchInput = z.infer<typeof vectorStoreSearchInputSchema>;
export type VectorStoreSearchOutput = z.infer<typeof vectorStoreSearchOutputSchema>;
