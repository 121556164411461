import { z } from 'zod';

export const getUserCommunityInputSchema = z.object({
  // add path params : have to be the same name as path in TRCP
  id: z.string(),
  // add query params
});

export const getUserCommunityOutputSchema = z.object({
  // add response
});

export type GetUserCommunityOutput = z.infer<typeof getUserCommunityOutputSchema>;
