import { z } from 'zod';

import { communityWebhookEventType } from './common';

export const listAvailableEventTypesPathSchema = z.object({
  subdomain: z.string(),
});

export const listAvailableEventTypesInputSchema = listAvailableEventTypesPathSchema;

export const listAvailableEventTypesOutputSchema = z.array(communityWebhookEventType);

export type ListAvailableEventTypesInput = z.infer<typeof listAvailableEventTypesInputSchema>;

export type ListAvailableEventTypesOutput = z.infer<typeof listAvailableEventTypesOutputSchema>;
