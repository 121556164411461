import {
  getAnalyticsPathSchema,
  getRangeAnalyticsWithIntervalQuerySchema,
  getTimeSeriesOutputSchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const getMembers = query({
  method: 'GET',
  path: '/get-members',
  pathParams: getAnalyticsPathSchema,
  query: getRangeAnalyticsWithIntervalQuerySchema,
  responses: {
    200: getTimeSeriesOutputSchema,
  },
});
