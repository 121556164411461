import { router } from '../../utils';
import { createReview } from './createReview';
import { getReview } from './getReview';
import { getReviewAlerts } from './getReviewAlerts';
import { getUserStats } from './getUserStats';
import { listReviewEvents } from './listReviewEvents';
import { listReviews } from './listReviews';
import { resetClaims } from './resetClaims';

export const reviewContract = router(
  {
    get: getReview,
    getReviewAlerts: getReviewAlerts,
    getUserStats: getUserStats,
    list: listReviews,
    listEvents: listReviewEvents,
    resetClaims: resetClaims,
    create: createReview,
  },
  {
    pathPrefix: '/communities/:subdomain/reviews',
  },
);
