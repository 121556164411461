import { updateUserBodySchema, updateUserOutputSchema, updateUserPathSchema } from '@zealy/schemas';

import { mutation } from '../../utils';

export const updateUser = mutation({
  method: 'PATCH',
  path: '/:id',
  pathParams: updateUserPathSchema,
  body: updateUserBodySchema,
  responses: {
    200: updateUserOutputSchema,
  },
});
