import { router } from '../../utils';
import { getTemplateQuest } from './getTemplateQuest';
import { listTemplateModules } from './listTemplateModules';
import { listTemplateQuests } from './listTemplateQuests';

export const templateContract = router(
  {
    listTemplateQuests,
    getTemplateQuest,
    listTemplateModules,
  },
  {
    pathPrefix: '/communities/:subdomain/templates',
  },
);
