import { getUserOutputSchema, getUserPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getUser = query({
  method: 'GET',
  path: '/:id',
  pathParams: getUserPathSchema,
  responses: {
    200: getUserOutputSchema,
    // 200: type<{ id: string; title: string; toto: string; }>(), // instead of using zod, we can use a TS type directly, only if we don't want output validation
    // 400: type<{ message: string }>(), // we can also define all error status codes this endpoint can return so the frontend can catch them with type safety
  },
});

// error example
// if (status === 200) {
//   console.log(body.email);
// } else if (status === 400) {
//   console.log(body.message);
// } else {
//   console.log('Something went wrong');
// }
