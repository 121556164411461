import { none, redirectOAuth2QuerySchema } from '@zealy/schemas';

import { query } from '../../utils';

export const redirectOAuth2 = query({
  method: 'GET',
  path: '/oauth2/redirect',
  query: redirectOAuth2QuerySchema,
  responses: {
    302: none,
  },
});
