import { listSprintsOutputSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const listSprints = query({
  method: 'GET',
  path: '/sprints',
  responses: {
    200: listSprintsOutputSchema,
  },
});
