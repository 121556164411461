import { z } from 'zod';

import { USERNAME_REGEXP } from './updateUser';

export const createUserV2BodySchema = z.object({
  name: z.string().min(3).regex(USERNAME_REGEXP),
});

export const createUserV2InputSchema = createUserV2BodySchema;

export type CreateUserV2Input = z.infer<typeof createUserV2InputSchema>;
