import { createPromotionBookingSchema } from '@zealy/schemas';

import { mutation, noBody } from '../../utils';

export const createPromotionBooking = mutation({
  method: 'POST',
  path: '',
  body: createPromotionBookingSchema,
  responses: {
    201: noBody(),
  },
});
