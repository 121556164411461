import { createWalletSessionBodySchema, createWalletSessionOutputSchema } from '@zealy/schemas';

import { mutation } from '../../utils';

export const createWalletSession = mutation({
  method: 'POST',
  path: '/wallet/session',
  body: createWalletSessionBodySchema,
  responses: {
    200: createWalletSessionOutputSchema,
  },
});
