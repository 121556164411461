import { z } from 'zod';

export const getHealthOutputSchema = z.object({
  uptime: z.number(),
  status: z.string(),
  date: z.date(),
  cloud: z.boolean(),
  modules: z.object({
    redis: z.string(),
    redisQueue: z.string(),
    database: z.string(),
  }),
});
