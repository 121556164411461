import {
  testClaimQuestBodySchema,
  testClaimQuestOutputSchema,
  testClaimQuestPathSchema,
} from '@zealy/schemas';

import { mutation } from '../../utils';

export const testClaimQuest = mutation({
  method: 'POST',
  path: '/test-claim',
  pathParams: testClaimQuestPathSchema,
  body: testClaimQuestBodySchema,
  responses: {
    200: testClaimQuestOutputSchema,
  },
});
