import { z } from 'zod';

import { dateWithoutTime } from '../common';

export const listPromotionBookingSchema = z.object({
  fullyBookedSlots: z.array(dateWithoutTime).optional(),
  bookings: z.array(dateWithoutTime),
});

export const listPromotionBookingQuerySchema = z.object({
  from: dateWithoutTime.optional(),
  to: dateWithoutTime.optional(),
  includeFullyBookedInformation: z.enum(['true', 'false']).optional().default('true'),
});

export type ListPromotionBooking = z.infer<typeof listPromotionBookingSchema>;

export type ListPromotionBookingInput = z.infer<typeof listPromotionBookingQuerySchema>;
export type ListPromotionBookingOutput = z.infer<typeof listPromotionBookingSchema>;
