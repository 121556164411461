import { getInvitationOutputSchema, getInvitationPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getInvitation = query({
  method: 'GET',
  path: '/:id',
  pathParams: getInvitationPathSchema,
  responses: {
    200: getInvitationOutputSchema,
  },
});
