import { z } from 'zod';

import { nonNegativeIntSchema } from '@zealy/utils';

export const createReviewPathSchema = z.object({
  subdomain: z.string(),
});

export const createReviewBodySchema = z.object({
  claimedQuestIds: z.array(z.string().uuid()).min(1),
  status: z.enum(['fail', 'success', 'pending']),
  mark: z.enum(['star', 'flag']).optional(),
  comment: z.string().optional(),
  bonusXP: nonNegativeIntSchema.max(1000).optional().default(0),
});

export const createReviewOutputSchema = z.object({
  reviewedClaimQuestIds: z.array(z.string().uuid()),
  status: z.enum(['fail', 'success', 'pending']),
  mark: z.enum(['star', 'flag']).optional(),
  comment: z.string().optional(),
  rejectedQuests: z
    .array(
      z.object({
        ids: z.array(z.string().uuid()),
        reason: z.string(),
      }),
    )
    .optional(),
});

export type CreateReviewInput = z.infer<typeof createReviewBodySchema>;
export type CreateReviewOutput = z.infer<typeof createReviewOutputSchema>;
