import {
  updateLeaderboardSprintBodySchema,
  updateLeaderboardSprintOutputSchema,
  updateLeaderboardSprintPathSchema,
} from '@zealy/schemas';

import { mutation } from '../../utils';

export const updateLeaderboardSprint = mutation({
  method: 'PATCH',
  path: '/communities/:subdomain/leaderboard/sprint',
  pathParams: updateLeaderboardSprintPathSchema,
  body: updateLeaderboardSprintBodySchema,
  responses: {
    200: updateLeaderboardSprintOutputSchema,
  },
});
