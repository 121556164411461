import {
  getTokenContractMetadataOutputSchema,
  getTokenContractMetadataPathSchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const getTokenContractMetadata = query({
  method: 'GET',
  path: '/:network/token/:contract/metadata',
  pathParams: getTokenContractMetadataPathSchema,
  responses: {
    200: getTokenContractMetadataOutputSchema,
  },
});
