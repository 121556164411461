import {
  regenerateCommunityWebhookSecretOutputSchema,
  regenerateCommunityWebhookSecretPathSchema,
} from '@zealy/schemas';

import { mutation, noBody } from '../../utils';

export const regenerateCommunityWebhookSecret = mutation({
  method: 'POST',
  path: '/:subdomain/webhooks/:webhookId/secret/regenerate',
  pathParams: regenerateCommunityWebhookSecretPathSchema,
  body: noBody(),
  responses: {
    200: regenerateCommunityWebhookSecretOutputSchema,
  },
});
