import { z } from 'zod';

export const listUserApiKeyHashInputSchema = z.object({
  // add path params : have to be the same name as path in TRCP
  // add query params
  myQueryParam: z.boolean(),
});

export const listUserApiKeyHashOutputSchema = z.array(
  z.object({
    // add response
  }),
);

export type ListUserApiKeyHashOutput = z.infer<typeof listUserApiKeyHashOutputSchema>;
