import { z } from 'zod';

export const getReferralCodePathSchema = z.object({
  subdomain: z.string(),
  userId: z.string().uuid(),
});

export const getReferralCodeInputSchema = getReferralCodePathSchema;

export const getReferralCodeOutputSchema = z.object({
  code: z.string(),
});

export type GetReferralCodeInput = z.infer<typeof getReferralCodeInputSchema>;

export type GetReferralCodeOutput = z.infer<typeof getReferralCodeOutputSchema>;
