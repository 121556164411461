import { z } from 'zod';

export const createUserApiKeyHashBodySchema = z.object({
  // add body
});

export const createUserApiKeyHashPathSchema = z.object({
  // add path params : have to be the same name as path in TRCP
});

export const createUserApiKeyHashInputSchema = createUserApiKeyHashPathSchema.merge(
  createUserApiKeyHashBodySchema,
);

export const createUserApiKeyHashOutputSchema = z.object({
  // add response
});

export type CreateUserApiKeyHashInput = z.infer<typeof createUserApiKeyHashBodySchema>;

export type CreateUserApiKeyHashOutput = z.infer<typeof createUserApiKeyHashOutputSchema>;
