import { createEmbeddingInputSchema, createEmbeddingOutputSchema } from '@zealy/schemas';

import { mutation } from '../../utils';

export const createEmbedding = mutation({
  method: 'POST',
  path: '/embedding',
  body: createEmbeddingInputSchema,
  responses: {
    200: createEmbeddingOutputSchema,
  },
});
