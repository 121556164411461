import { updatePartnershipRequestInputSchema } from '@zealy/schemas';

import { mutation, noBody } from '../../utils';

export const updatePartnershipRequest = mutation({
  method: 'PATCH',
  path: '/:partnershipId',
  body: updatePartnershipRequestInputSchema,
  responses: {
    204: noBody(),
  },
});
