import { getTemplateQuestOutputSchema, getTemplateQuestPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getTemplateQuest = query({
  method: 'GET',
  path: '/quests/:questId',
  pathParams: getTemplateQuestPathSchema,
  responses: {
    200: getTemplateQuestOutputSchema,
  },
});
