import { z } from 'zod';

export const createWalletSessionBodySchema = z.object({
  address: z.string(),
});

export const createWalletSessionInputSchema = createWalletSessionBodySchema;

export const createWalletSessionOutputSchema = z.object({
  address: z.string(),
  id: z.string(),
  nonce: z.string(),
  status: z.enum(['pending', 'verified', 'fail']),
});

export type CreateWalletSessionInput = z.infer<typeof createWalletSessionInputSchema>;

export type CreateWalletSessionOutput = z.infer<typeof createWalletSessionOutputSchema>;
