import {
  getMemberBySocialIdOutputSchema,
  getMemberBySocialIdPathSchema,
  getMemberBySocialIdQuerySchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const getMemberBySocialId = query({
  summary: 'Find a user by social id',
  method: 'GET',
  path: '',
  pathParams: getMemberBySocialIdPathSchema,
  query: getMemberBySocialIdQuerySchema,
  responses: {
    200: getMemberBySocialIdOutputSchema,
  },
});
