import { z } from 'zod';

export const generateNonceBodySchema = z.object({});

export const generateNonceInputSchema = generateNonceBodySchema;

export const generateNonceOutputSchema = z.string();

export type GenerateNonceInput = z.infer<typeof generateNonceInputSchema>;

export type GenerateNonceOutput = z.infer<typeof generateNonceOutputSchema>;
