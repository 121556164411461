import { router } from '../../utils';
import { getInvitation } from './getInvitation';

export const invitationContract = router(
  {
    get: getInvitation,
  },
  {
    pathPrefix: '/communities/:subdomain/invitation',
  },
);
