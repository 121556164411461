import { z } from 'zod';

const messageSchema = z.string();

// In case we want something else than telegram
const contactSchema = z.object({
  telegramHandle: z.string().optional(),
});

const partialCommunity = z.object({
  id: z.string().uuid(),
  image: z.string().nullable(),
  name: z.string().nullable(),
  subdomain: z.string(),
  description: z.string().nullable(),
  totalMembers: z.number(),
  totalTwitterFollowers: z.number(),
  totalDiscordMembers: z.number(),
  twitter: z.string().nullable(),
  discord: z.string().nullable(),
  website: z.string().nullable(),
  createdAt: z.string().datetime().nullable(),
  sector: z.string().nullable(),
  partnershipsEnabled: z.boolean(),
  partnershipsRequiredMember: z.number(),
  rank: z.number().nullable(),
});

const statusSchema = z.enum(['accepted', 'refused', 'pending', 'cancelled']).default('pending');

export const partnershipRequestSchema = z.object({
  id: z.string().uuid(),
  requestingCommunity: partialCommunity,
  targetedCommunity: partialCommunity,
  requestingUser: z.object({
    id: z.string().uuid(),
    name: z.string().nullable(),
    avatar: z.string().nullable(),
    email: z.string().nullable(),
  }),
  acceptingUser: z
    .object({
      email: z.string(),
    })
    .nullable(),
  createdAt: z.string().datetime(),
  answeredAt: z.string().datetime().nullable(),
  message: messageSchema,
  contact: contactSchema,
  status: statusSchema,
});

export const createPartnershipRequestInputSchema = z.object({
  targetedCommunityId: z.string().uuid(),
  message: messageSchema,
  contact: contactSchema,
});

export const updatePartnershipRequestInputSchema = z.object({
  status: statusSchema,
});

export const partnershipsRequestOutputSchema = z.array(partnershipRequestSchema);

const partnershipsRequestQueryPendingSchema = z.object({
  status: z.enum(['pending']),
  position: z.enum(['incoming', 'requested']),
});

const partnershipsRequestQueryOtherSchema = z.object({
  status: z.enum(['accepted', 'refused']),
});

export const partnershipsRequestQuerySchema = z.discriminatedUnion('status', [
  partnershipsRequestQueryPendingSchema,
  partnershipsRequestQueryOtherSchema,
]);

export const partialCommunities = z.array(partialCommunity);

export const partnershipsParamsSchema = z.object({
  subdomain: z.string(),
});

export const searchPartnershipCommunitiesQuerySchema = z.object({
  search: z.string(),
  page: z.string(),
  pageSize: z.string(),
});

export type PartnershipsRequestQuery = z.infer<typeof partnershipsRequestQuerySchema>;
export type PartnershipsRequestOutput = z.infer<typeof partnershipsRequestOutputSchema>;
export type PartnershipStatus = z.infer<typeof statusSchema>;
export type PartnershipRequest = z.infer<typeof partnershipRequestSchema>;
export type CreatePartnershipRequestInput = z.infer<typeof createPartnershipRequestInputSchema>;
export type UpdatePartnershipRequestInput = z.infer<typeof updatePartnershipRequestInputSchema>;
export type PartnershipCommunity = z.infer<typeof partialCommunity>;
export type PartialCommunity = z.infer<typeof partialCommunities>;
