import { getRewardsOutputSchema, getRewardsPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getQuestRewards = query({
  method: 'GET',
  path: '',
  pathParams: getRewardsPathSchema,
  responses: {
    200: getRewardsOutputSchema,
  },
});
