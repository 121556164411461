import { router } from '../../utils';
import { banMember } from './banMember';
import { getMember } from './getMember';
import { listInvites } from './listInvites';
import { setRole } from './setRole';

export const communityMemberContract = router(
  {
    ban: banMember,
    get: getMember,
    listInvites: listInvites,
    setRole: setRole,
  },
  {
    pathPrefix: '/communities/:subdomain/users',
  },
);
