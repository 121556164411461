import { z } from 'zod';

export const updateUserApiKeyHashBodySchema = z.object({});

export const updateUserApiKeyHashPathSchema = z.object({
  id: z.string().uuid(),
});

export const updateUserApiKeyHashInputSchema = updateUserApiKeyHashPathSchema.merge(
  updateUserApiKeyHashBodySchema,
);

export const updateUserApiKeyHashOutputSchema = z.object({
  // add response
});

export type UpdateUserApiKeyHashInput = z.infer<typeof updateUserApiKeyHashBodySchema>;

export type UpdateUserApiKeyHashOutput = z.infer<typeof updateUserApiKeyHashOutputSchema>;
