import { z } from 'zod';

import { communityWebhookEventStatusType } from './common';

export const listCommunityWebhookEventsQuerySchema = z.object({
  limit: z.coerce.number().min(1).max(100).default(100),
  page: z.coerce.number().min(0).default(0),
  statusFilter: z.preprocess(
    val => (typeof val === 'string' ? String(val).split(',') : val),
    z.array(communityWebhookEventStatusType),
  ),
});

export const listCommunityWebhookEventsPathSchema = z.object({
  subdomain: z.string(),
  webhookId: z.string().uuid(),
});

export const listCommunityWebhookEventsInputSchema = listCommunityWebhookEventsQuerySchema.merge(
  listCommunityWebhookEventsPathSchema,
);

export const listCommunityWebhookEventsOutputSchema = z.object({
  items: z.array(
    z.object({
      id: z.string().uuid(),
      webhookId: z.string().uuid(),
      status: z.enum(['success', 'exhausted', 'failed', 'pending']),
      createdAt: z.string().datetime().nullable(),
      updatedAt: z.string().datetime().nullable(),
      retryAt: z.string().datetime().nullable(),
      eventType: z.string(),
      tries: z.number(),
    }),
  ),
  page: z.number(),
});

export type ListCommunityWebhookEventsInput = z.infer<typeof listCommunityWebhookEventsInputSchema>;

export type ListCommunityWebhookEventsOutput = z.infer<
  typeof listCommunityWebhookEventsOutputSchema
>;
