import {
  getAnalyticsPathSchema,
  getRangeAnalyticsQuerySchema,
  getTopCountriesOutputSchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const getTopCountries = query({
  method: 'GET',
  path: '/top-countries',
  pathParams: getAnalyticsPathSchema,
  query: getRangeAnalyticsQuerySchema,
  responses: {
    200: getTopCountriesOutputSchema,
  },
});
