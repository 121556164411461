import { z } from 'zod';

import { questSchema } from './quest';

export const moduleSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  quests: z.array(questSchema),
  totalQuestCount: z.number(),
  color: z.string().nullish(),
  coverUrl: z.string().nullish(),
});
