import { router } from '../../utils';
import { createCommunityWebhook } from './createCommunityWebhook';
import { deleteCommunityWebhook } from './deleteCommunityWebhook';
import { getCommunityWebhookEvent } from './getCommunityWebhookEvent';
import { listAvailableEventTypes } from './listAvailableEventTypes';
import { listCommunityWebhookEvents } from './listCommunityWebhookEvents';
import { listCommunityWebhooks } from './listCommunityWebhooks';
import { regenerateCommunityWebhookSecret } from './regenerateCommunityWebhookSecret';
import { retryCommunityWebhookEvent } from './retryCommunityWebhookEvent';
import { testCommunityWebhookEvent } from './testCommunityWebhookEvent';
import { updateCommunityWebhook } from './updateCommunityWebhook';

export const communityWebhookContract = router(
  {
    create: createCommunityWebhook,
    delete: deleteCommunityWebhook,
    getEvent: getCommunityWebhookEvent,
    list: listCommunityWebhooks,
    listAvailableEventTypes: listAvailableEventTypes,
    listEvents: listCommunityWebhookEvents,
    regenerateSecret: regenerateCommunityWebhookSecret,
    retryEvent: retryCommunityWebhookEvent,
    testEvent: testCommunityWebhookEvent,
    update: updateCommunityWebhook,
  },
  {
    pathPrefix: '/communities',
  },
);
