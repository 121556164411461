import { z } from 'zod';

export const walletLoginBodySchema = z.object({
  sessionId: z.string(),
  signature: z.string(),
  invitationId: z.string().optional(),
  reCaptchaToken: z.string(),
});

export const walletLoginInputSchema = walletLoginBodySchema;

export type WalletLoginInput = z.infer<typeof walletLoginInputSchema>;
