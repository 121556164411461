import {
  generateMagicQuestBodySchema,
  generateMagicQuestOutputSchema,
  generateMagicQuestPathSchema,
} from '@zealy/schemas';

import { mutation } from '../../utils';

export const generateMagicQuest = mutation({
  method: 'POST',
  path: '/generate',
  pathParams: generateMagicQuestPathSchema,
  body: generateMagicQuestBodySchema,
  responses: {
    200: generateMagicQuestOutputSchema,
  },
});
