import { listTemplateQuestsOutputSchema, listTemplateQuestsPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const listTemplateQuests = query({
  method: 'GET',
  path: '/quests',
  pathParams: listTemplateQuestsPathSchema,
  responses: {
    200: listTemplateQuestsOutputSchema,
  },
});
