import { router } from '../../utils';
import { seedClaims } from './seedClaims';

export const devContract = router(
  {
    seedClaims: seedClaims,
  },
  {
    pathPrefix: '/dev',
  },
);
