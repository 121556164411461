import { z } from 'zod';

import { tipTapDocumentSchema } from '../common';

export const baseSprintSchema = z.object({
  id: z.string().uuid(),
  rewards: z.union([tipTapDocumentSchema, z.object({})]).nullable(),
  rewardZone: z.number().nullable(),
  endingAt: z.string().datetime(),
  startingAt: z.string().datetime(),
  createdAt: z.string().datetime(),
  v2: z.boolean(),
  name: z.string().optional().nullable(),
  description: z.string().max(3000).optional().nullable(),
  image: z.string().optional().nullable(),
  color: z.string().optional().nullable(),
});
