import { z } from 'zod';

export const updateCommunityBodySchema = z.object({});

export const updateCommunityPathSchema = z.object({
  id: z.string().uuid(),
});

export const updateCommunityInputSchema =
  updateCommunityPathSchema.merge(updateCommunityBodySchema);

export const updateCommunityOutputSchema = z.object({
  // add response
});

export type UpdateCommunityInput = z.infer<typeof updateCommunityBodySchema>;

export type UpdateCommunityOutput = z.infer<typeof updateCommunityOutputSchema>;
