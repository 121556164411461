import { router } from '../../utils';
import { listNotifications } from './listNotifications';
import { setNotificationsReadStatus } from './setNotificationsReadStatus';

export const notificationContract = router(
  {
    list: listNotifications,
    setReadStatus: setNotificationsReadStatus,
  },
  {
    pathPrefix: '/communities/:subdomain/users/:userId/notifications',
  },
);
