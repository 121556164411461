import { router } from '../../utils';
import { createEmbedding } from './createEmbedding';
import { vectorStoreSearch } from './vectorStoreSearch';

export const aiCommunityManagerContract = router(
  {
    vectorStoreSearch,
    createEmbedding,
  },
  {
    pathPrefix: '/ai-community-manager',
  },
);
