import { z } from 'zod';

export const updateSubscriptionBodySchema = z.object({
  newUsagePriceId: z
    .string()
    .regex(/^price_\w+$/)
    .optional(),
  newBasePriceId: z
    .string()
    .regex(/^price_\w+$/)
    .optional(),
  metadata: z.record(z.string()).optional(),
  isUpgrade: z.boolean().optional(),
});

export const updateSubscriptionPathSchema = z.object({
  subdomain: z.string(),
});

export const updateSubscriptionInputSchema = updateSubscriptionBodySchema.merge(
  updateSubscriptionPathSchema,
);

export const updateSubscriptionOutputSchema = z.object({
  planId: z.string(),
  pendingInvoiceUrl: z.string().nullable(),
});

export type UpdateSubscriptionInput = z.infer<typeof updateSubscriptionInputSchema>;
export type UpdateSubscriptionOutput = z.infer<typeof updateSubscriptionOutputSchema>;
