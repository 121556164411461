import { z } from 'zod';

import { baseSprintSchema } from '../leaderboardSprint/common';

export const listCommunitySprintsQuerySchema = z.object({
  onlyCurrent: z.coerce.boolean().default(false),
});

export const listCommunitySprintsPathSchema = z.object({
  subdomain: z.string(),
});

export const listCommunitySprintsInputSchema = listCommunitySprintsQuerySchema.merge(
  listCommunitySprintsPathSchema,
);

export const listCommunitySprintsOutputSchema = z.object({
  data: z.array(
    baseSprintSchema.extend({
      includedQuestIds: z.array(z.string().uuid()),
    }),
  ),
});

export type ListCommunitySprintsInput = z.infer<typeof listCommunitySprintsInputSchema>;
export type ListCommunitySprintsOutput = z.infer<typeof listCommunitySprintsOutputSchema>;
