import { router } from '../../utils';
import { listCommunity } from './listCommunity';
import { updateCommunitySettingsPartnerships } from './updateCommunitySettingsPartnerships';

export const communityContract = router(
  {
    list: listCommunity,
    updateSettings: updateCommunitySettingsPartnerships,
  },
  {
    pathPrefix: '/communities',
  },
);
