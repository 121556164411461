import { z } from 'zod';

export const sendOtpBodySchema = z.object({
  email: z.string().email().max(255),
  invitationId: z.string().optional(),
  reCaptchaToken: z.string(),
});

export const sendOtpInputSchema = sendOtpBodySchema;

export type SendOtpInput = z.infer<typeof sendOtpInputSchema>;
