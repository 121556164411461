import { z } from 'zod';

export const getTopCommunitiesOutputSchema = z.object({
  isComputed: z.boolean(),
  data: z
    .array(
      z.object({
        communityId: z.string(),
        name: z.string().nullable(),
        subdomain: z.string().nullable(),
        value: z.number(),
      }),
    )
    .nullable(),
});
