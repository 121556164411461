import {
  listCommunitySprintsOutputSchema,
  listCommunitySprintsPathSchema,
  listCommunitySprintsQuerySchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const listCommunitySprints = query({
  method: 'GET',
  path: '/sprint',
  pathParams: listCommunitySprintsPathSchema,
  query: listCommunitySprintsQuerySchema,
  responses: {
    200: listCommunitySprintsOutputSchema,
  },
});
