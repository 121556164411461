import { router } from '../../utils';
import { listModules } from './listModules';

export const publicModuleContract = router(
  {
    listModules,
  },
  {
    pathPrefix: '/communities/:subdomain/modules',
  },
);
