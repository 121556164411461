import { createUserBodySchema, createUserOutputSchema } from '@zealy/schemas';

import { mutation } from '../../utils';

export const createUser = mutation({
  method: 'POST',
  path: '',
  body: createUserBodySchema,
  responses: {
    200: createUserOutputSchema,
  },
});
