import {
  updateSubscriptionBodySchema,
  updateSubscriptionOutputSchema,
  updateSubscriptionPathSchema,
} from '@zealy/schemas';

import { mutation } from '../../utils';

export const updateSubscription = mutation({
  method: 'PATCH',
  path: '',
  pathParams: updateSubscriptionPathSchema,
  body: updateSubscriptionBodySchema,
  responses: {
    200: updateSubscriptionOutputSchema,
  },
});
