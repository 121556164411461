import { z } from 'zod';

export const getTopQuestsOutputSchema = z.object({
  isComputed: z.boolean(),
  data: z
    .array(
      z.object({
        name: z.string().nullable(),
        value: z.number(),
        successRate: z.number(),
        questId: z.string(),
      }),
    )
    .nullable(),
});
