import { z } from 'zod';

export const deleteZealyConnectForMemberPathSchema = z.object({
  subdomain: z.string(),
  userId: z.string().uuid(),
});

export const deleteZealyConnectForMemberQuerySchema = z.object({
  redirectUrl: z.string(),
});
