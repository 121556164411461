import { z } from 'zod';

import { augmentedTokenSchema, decimalSchema, networkSchema } from '@zealy/utils';

export const tokenRewardsSchema = z.object({
  address: z.string(),
  contract: z.string(),
  createdAt: z.string(),
  id: z.string(),
  network: networkSchema,
  questId: z.string(),
  status: z.enum(['success', 'pending']),
  value: decimalSchema,
  transactionId: z.string().optional().nullable(),
});

export const listTokenRewardSchema = z.object({
  page: z.number(),
  items: z.array(tokenRewardsSchema.merge(augmentedTokenSchema)),
});

export const listTokenRewardQuerySchema = z.object({
  limit: z.coerce.number().min(1).max(100).default(100),
  page: z.coerce.number().min(0).default(0),
  status: z.enum(['success', 'pending']).optional(),
});

export type ListTokenRewardInput = z.infer<typeof listTokenRewardQuerySchema>;
export type ListTokenRewardOutput = z.infer<typeof listTokenRewardSchema>;
