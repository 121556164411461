import { z } from 'zod';

export const cancelSubscriptionPathSchema = z.object({
  subdomain: z.string(),
});

export const cancelSubscriptionBodySchema = z.object({
  comment: z.string().optional(),
  feedback: z
    .enum([
      'too_expensive',
      'missing_features',
      'switched_service',
      'unused',
      'customer_service',
      'too_complex',
      'low_quality',
      'other',
    ])
    .optional(),
});

export const cancelSubscriptionInputSchema = cancelSubscriptionBodySchema.merge(
  cancelSubscriptionPathSchema,
);

export type CancelSubscriptionInput = z.infer<typeof cancelSubscriptionInputSchema>;
