import { router } from '../../utils';
import { listTokenReward } from './listTokenReward';
import { updateTokenRewardTransaction } from './updateTokenRewardTransaction';

export const tokenRewardContract = router(
  {
    listTokenReward,
    updateTokenRewardTransaction,
  },
  {
    pathPrefix: '/communities/:subdomain/token-reward',
  },
);
