import {
  listInvitesOutputSchema,
  listInvitesPathSchema,
  listInvitesQuerySchema,
} from '@zealy/schemas';

import { query } from '../../utils';

export const listInvites = query({
  method: 'GET',
  path: '/:userId/invites',
  pathParams: listInvitesPathSchema,
  query: listInvitesQuerySchema,
  responses: {
    200: listInvitesOutputSchema,
  },
});
