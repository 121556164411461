import { getUserStatsOutputSchema, getUserStatsPathSchema } from '@zealy/schemas';

import { query } from '../../utils';

export const getUserStats = query({
  method: 'GET',
  path: '/users/:id',
  pathParams: getUserStatsPathSchema,
  responses: {
    200: getUserStatsOutputSchema,
  },
});
