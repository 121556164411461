import { z } from 'zod';

export const setRoleBodySchema = z.object({
  role: z.enum(['admin', 'editor', 'reviewer', 'guest', 'banned', 'suspend']),
});

export const setRolePathSchema = z.object({
  subdomain: z.string(),
  userId: z.string().uuid(),
});

export const setRoleInputSchema = setRoleBodySchema.merge(setRolePathSchema);

export type SetRoleInput = z.infer<typeof setRoleInputSchema>;
